const MinerallGuildIcon = () => {
  return (
    <svg
      width="83"
      height="80"
      viewBox="0 0 83 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.393734 65.8295L38.8036 1.29171C39.0141 0.936111 39.3056 0.634137 39.6456 0.414006C40.8763 -0.384673 42.4902 0.00761099 43.254 1.28606L81.6827 65.5247C82.3007 66.5605 82.1847 67.8982 81.3993 68.8041L71.9966 79.6018C71.6862 79.9574 71.2031 80.09 70.7659 79.9377C67.7189 78.868 64.3211 77.9452 60.6642 77.1888C60.4698 77.1493 60.2917 77.0562 60.146 76.9207C59.7034 76.503 59.671 75.789 60.0704 75.329L66.8715 67.5144C67.6595 66.6113 67.7729 65.2764 67.1549 64.2406L43.2783 24.3292C43.0677 23.9736 42.779 23.6745 42.4362 23.4515C41.2082 22.6585 39.5916 23.0536 38.8279 24.3349L16.0983 62.5219C15.9417 62.7872 15.877 63.0976 15.912 63.4053C15.9984 64.1531 16.6515 64.6865 17.3694 64.5934C30.3697 62.0055 41.7318 61.7035 55.2854 63.5972L55.3286 63.6028C55.5768 63.6367 55.8063 63.7609 55.9736 63.9556C56.3676 64.4071 56.3352 65.107 55.9007 65.5163C53.1371 68.1296 50.6407 71.0139 48.4762 74.0929C48.2468 74.4203 47.8663 74.5981 47.4803 74.567L47.4614 74.5642C33.943 72.8596 21.0398 74.8013 9.35657 78.0694C8.94635 78.1823 8.50644 78.0355 8.22306 77.7081L0.685209 69.1089C-0.105551 68.2058 -0.2243 66.8681 0.393734 65.8295Z"
        fill="white"
      />
    </svg>
  );
};

export default MinerallGuildIcon;
