import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1 1 400px;
  justify-content: space-around;
  align-items: center;

  height: 400px;
  padding: 40px;
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.section')};

  text-align: center;
`;

export const Title = styled(Text)`
  font-size: ${themeGet('fontSizes.base')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.md')}px;
  }
`;
