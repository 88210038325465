import { useTranslation } from 'react-i18next';

import { Item, Title } from './technology-item.styles';

const TechnologyItem = ({ icon, title }) => {
  const { t } = useTranslation();

  return (
    <Item>
      {icon}
      <Title weight="semi">{t(`${title}`)}</Title>
    </Item>
  );
};

export default TechnologyItem;
