import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import { MainContentArea, Container, Row } from 'assets/styles/pages.style';
import Top from 'components/promo/top/top';
import Technologies from 'components/promo/technologies/technologies';
import Stack from 'components/promo/stack/stack';
import Start from 'components/promo/start/start';

export default function PromoPage() {
  return (
    <MainContentArea pt={[3, 6]}>
      <Container>
        <Row mb={[5]}>
          <Top />
        </Row>
        <Row>
          <Technologies />
        </Row>
        <Row>
          <Stack />
        </Row>
        <Row mb={0}>
          <Start />
        </Row>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.promo.title')} />;
};
