import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex-direction: row;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 360px;
  padding: 48px;
  border-radius: ${themeGet('radii.base')};

  background-color: ${(props) =>
    props.isAccented
      ? themeGet('colors.primary.regular')
      : themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};

  text-align: center;

  p,
  h3 {
    color: ${(props) =>
      props.isAccented
        ? themeGet('colors.text.light')
        : themeGet('colors.body.text')};
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex: 1 1 620px;
  }
`;
