import { useTranslation } from 'react-i18next';

import Heading from 'components/common/heading/heading';
import { Container, Block } from './top.styles';
import { topData } from './top-data';

const Top = () => {
  const { t } = useTranslation();

  return (
    <Container>
      {topData.map((item) => (
        <Block key={item.title} isAccented={item.isAccented}>
          {item.icon}
          <Heading variant="h3" mb="0">
            {t(`${item.title}`)}
          </Heading>
          <p>{t(`${item.description}`)}</p>
        </Block>
      ))}
    </Container>
  );
};

export default Top;
