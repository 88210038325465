const MiningSoftwareIcon = () => {
  return (
    <svg
      width="157"
      height="140"
      viewBox="0 0 157 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9961 102.605H156.203V139.364H11.9961V102.605Z"
        fill="#97ADFF"
      />
      <path
        d="M159.033 34.7452H153.378V17.7796C153.377 17.03 153.079 16.3113 152.549 15.7812C152.019 15.2511 151.3 14.9529 150.551 14.952H14.8265C14.0768 14.9529 13.3581 15.2511 12.828 15.7812C12.298 16.3113 11.9998 17.03 11.9989 17.7796V34.7452H6.34375V17.7796C6.34629 15.5306 7.24083 13.3745 8.8311 11.7842C10.4214 10.194 12.5775 9.29942 14.8265 9.29688H150.551C152.8 9.29942 154.956 10.194 156.546 11.7842C158.136 13.3745 159.031 15.5306 159.033 17.7796V34.7452Z"
        fill="#6487FF"
      />
      <path
        d="M0.6875 51.7109H164.688V102.607H0.6875V51.7109Z"
        fill="#97ADFF"
      />
      <path
        d="M0.6875 34.7422H164.688V51.7077H0.6875V34.7422Z"
        fill="#5771E5"
      />
      <path
        d="M20.4805 0.8125H43.1012V40.3987H20.4805V0.8125Z"
        fill="#D3DCFF"
      />
      <path d="M28.9648 0.8125H34.62V40.3987H28.9648V0.8125Z" fill="#96AEFF" />
      <path
        d="M54.4102 0.8125H77.0308V40.3987H54.4102V0.8125Z"
        fill="#D3DCFF"
      />
      <path
        d="M62.8945 0.8125H68.5497V40.3987H62.8945V0.8125Z"
        fill="#96AEFF"
      />
      <path
        d="M88.3438 0.8125H110.964V40.3987H88.3438V0.8125Z"
        fill="#D3DCFF"
      />
      <path
        d="M96.8242 0.8125H102.479V40.3987H96.8242V0.8125Z"
        fill="#96AEFF"
      />
      <path
        d="M122.273 0.8125H144.894V40.3987H122.273V0.8125Z"
        fill="#D3DCFF"
      />
      <path
        d="M130.758 0.8125H136.413V40.3987H130.758V0.8125Z"
        fill="#96AEFF"
      />
      <path
        d="M20.4805 111.09H26.1356V130.883H20.4805V111.09Z"
        fill="#5771E5"
      />
      <path
        d="M31.7891 111.09H37.4442V130.883H31.7891V111.09Z"
        fill="#5771E5"
      />
      <path
        d="M45.9297 111.09H62.8952V119.573L60.0676 122.4V130.883H48.7573V122.4L45.9297 119.573V111.09Z"
        fill="#D3DCFF"
      />
      <path
        d="M74.2031 102.605H156.203V128.054H74.2031V102.605Z"
        fill="#5771E5"
      />
      <path
        d="M82.6875 108.262H91.1703V113.917H82.6875V108.262Z"
        fill="#6487FF"
      />
      <path
        d="M82.6875 116.742H91.1703V122.397H82.6875V116.742Z"
        fill="#6487FF"
      />
      <path
        d="M96.8242 102.605H102.479V128.054H96.8242V102.605Z"
        fill="#97ADFF"
      />
      <path
        d="M110.964 116.745C112.526 116.745 113.792 115.479 113.792 113.917C113.792 112.356 112.526 111.09 110.964 111.09C109.403 111.09 108.137 112.356 108.137 113.917C108.137 115.479 109.403 116.745 110.964 116.745Z"
        fill="#97ADFF"
      />
      <path
        d="M119.445 116.745C121.006 116.745 122.272 115.479 122.272 113.917C122.272 112.356 121.006 111.09 119.445 111.09C117.883 111.09 116.617 112.356 116.617 113.917C116.617 115.479 117.883 116.745 119.445 116.745Z"
        fill="#97ADFF"
      />
      <path
        d="M127.929 116.745C129.491 116.745 130.757 115.479 130.757 113.917C130.757 112.356 129.491 111.09 127.929 111.09C126.368 111.09 125.102 112.356 125.102 113.917C125.102 115.479 126.368 116.745 127.929 116.745Z"
        fill="#97ADFF"
      />
      <path
        d="M136.41 102.605H142.065V128.054H136.41V102.605Z"
        fill="#97ADFF"
      />
      <path
        d="M144.895 108.262H150.55V113.917H144.895V108.262Z"
        fill="#6487FF"
      />
      <path
        d="M144.895 116.742H150.55V122.397H144.895V116.742Z"
        fill="#6487FF"
      />
      <path
        d="M41.6884 77.1588C41.6854 80.7077 40.3469 84.1255 37.9389 86.7325C35.531 89.3395 32.23 90.9447 28.6925 91.2289C25.1551 91.5132 21.6402 90.4556 18.8469 88.2666C16.0535 86.0775 14.1864 82.9173 13.6168 79.4145C13.0472 75.9116 13.8168 72.3226 15.7726 69.3613C17.7284 66.4 20.7271 64.2832 24.1724 63.432C27.6177 62.5807 31.2571 63.0574 34.367 64.7671C37.4769 66.4768 39.8294 69.2943 40.9567 72.6594C41.4422 74.1098 41.6893 75.6293 41.6884 77.1588Z"
        fill="#6487FF"
      />
      <path
        d="M41.686 77.1575C41.6871 78.3546 41.5363 79.547 41.2371 80.7061L38.4131 77.882C37.5333 77.0045 36.3841 76.4482 35.1501 76.3027C33.916 76.1572 32.6689 76.4309 31.6092 77.0797C33.1416 78.9725 34.0137 81.3141 34.0928 83.7481C34.172 86.1822 33.4537 88.5755 32.0475 90.5638C29.4481 91.4314 26.6533 91.5296 23.9994 90.8465L26.8235 88.0225C27.701 87.1427 28.2573 85.9935 28.4028 84.7594C28.5483 83.5254 28.2747 82.2783 27.6258 81.2186C25.9296 82.593 23.8676 83.4397 21.695 83.6538C21.3239 83.6892 20.9528 83.7069 20.5816 83.7069C18.2736 83.7094 16.0223 82.9915 14.1418 81.6533C13.2742 79.0551 13.176 76.2615 13.8591 73.6088L16.6831 76.4329C17.5628 77.3104 18.7121 77.8667 19.9461 78.0122C21.1801 78.1577 22.4273 77.8841 23.487 77.2352C21.9546 75.3424 21.0824 73.0009 21.0033 70.5668C20.9242 68.1327 21.6424 65.7395 23.0487 63.7512C25.6481 62.8836 28.4428 62.7854 31.0967 63.4684L28.2727 66.2925C27.3951 67.1722 26.8389 68.3214 26.6934 69.5555C26.5478 70.7895 26.8215 72.0366 27.4703 73.0963C29.3631 71.5639 31.7047 70.6918 34.1387 70.6127C36.5728 70.5335 38.9661 71.2518 40.9544 72.6581C41.44 74.1084 41.6871 75.628 41.686 77.1575Z"
        fill="#466BE7"
      />
      <path
        d="M27.5497 82.8143C26.4312 82.8143 25.3379 82.4826 24.4079 81.8612C23.4779 81.2398 22.753 80.3566 22.325 79.3232C21.897 78.2899 21.785 77.1528 22.0032 76.0558C22.2214 74.9588 22.76 73.9512 23.5509 73.1603C24.3418 72.3694 25.3494 71.8308 26.4464 71.6126C27.5434 71.3944 28.6805 71.5064 29.7139 71.9344C30.7472 72.3624 31.6304 73.0872 32.2518 74.0172C32.8732 74.9472 33.2049 76.0406 33.2049 77.1591C33.2032 78.6584 32.6068 80.0958 31.5466 81.156C30.4864 82.2162 29.049 82.8126 27.5497 82.8143Z"
        fill="#C1CCEC"
      />
      <path
        d="M78.4462 77.1588C78.4432 80.7077 77.1047 84.1255 74.6967 86.7325C72.2888 89.3395 68.9878 90.9447 65.4504 91.2289C61.9129 91.5132 58.398 90.4556 55.6047 88.2666C52.8113 86.0775 50.9442 82.9173 50.3746 79.4145C49.805 75.9116 50.5746 72.3226 52.5304 69.3613C54.4862 66.4 57.4849 64.2832 60.9302 63.432C64.3755 62.5807 68.0149 63.0574 71.1248 64.7671C74.2347 66.4768 76.5872 69.2943 77.7145 72.6594C78.2 74.1098 78.4471 75.6293 78.4462 77.1588Z"
        fill="#6487FF"
      />
      <path
        d="M78.4477 77.1575C78.4488 78.3546 78.298 79.547 77.9988 80.7061L75.1748 77.882C74.2951 77.0045 73.1458 76.4482 71.9118 76.3027C70.6777 76.1572 69.4306 76.4309 68.3709 77.0797C69.9033 78.9725 70.7754 81.3141 70.8545 83.7481C70.9337 86.1822 70.2154 88.5755 68.8092 90.5638C66.2098 91.4314 63.4151 91.5296 60.7612 90.8465L63.5852 88.0225C64.4628 87.1427 65.019 85.9935 65.1645 84.7594C65.31 83.5254 65.0364 82.2783 64.3875 81.2186C62.6913 82.593 60.6293 83.4397 58.4567 83.6538C58.0856 83.6892 57.7145 83.7069 57.3434 83.7069C55.0353 83.7094 52.7841 82.9915 50.9035 81.6533C50.0359 79.0551 49.9377 76.2615 50.6208 73.6088L53.4448 76.4329C54.3245 77.3104 55.4738 77.8667 56.7078 78.0122C57.9419 78.1577 59.189 77.8841 60.2487 77.2352C58.7163 75.3424 57.8442 73.0009 57.765 70.5668C57.6859 68.1327 58.4041 65.7395 59.8104 63.7512C62.4098 62.8836 65.2045 62.7854 67.8584 63.4684L65.0344 66.2925C64.1568 67.1722 63.6006 68.3214 63.4551 69.5555C63.3096 70.7895 63.5832 72.0366 64.2321 73.0963C66.1248 71.5639 68.4664 70.6918 70.9005 70.6127C73.3345 70.5335 75.7278 71.2518 77.7161 72.6581C78.2017 74.1084 78.4488 75.628 78.4477 77.1575Z"
        fill="#466BE7"
      />
      <path
        d="M64.3075 82.8143C63.189 82.8143 62.0957 82.4826 61.1657 81.8612C60.2357 81.2398 59.5108 80.3566 59.0828 79.3232C58.6548 78.2899 58.5428 77.1528 58.761 76.0558C58.9792 74.9588 59.5178 73.9512 60.3087 73.1603C61.0996 72.3694 62.1073 71.8308 63.2043 71.6126C64.3013 71.3944 65.4383 71.5064 66.4717 71.9344C67.505 72.3624 68.3882 73.0872 69.0096 74.0172C69.631 74.9472 69.9627 76.0406 69.9627 77.1591C69.961 78.6584 69.3646 80.0958 68.3045 81.156C67.2443 82.2162 65.8068 82.8126 64.3075 82.8143Z"
        fill="#C1CCEC"
      />
      <path
        d="M115.204 77.1588C115.201 80.7077 113.862 84.1255 111.455 86.7325C109.047 89.3395 105.746 90.9447 102.208 91.2289C98.6707 91.5132 95.1558 90.4556 92.3625 88.2666C89.5692 86.0775 87.702 82.9173 87.1324 79.4145C86.5628 75.9116 87.3324 72.3226 89.2882 69.3613C91.244 66.4 94.2427 64.2832 97.688 63.432C101.133 62.5807 104.773 63.0574 107.883 64.7671C110.993 66.4768 113.345 69.2943 114.472 72.6594C114.958 74.1098 115.205 75.6293 115.204 77.1588Z"
        fill="#6487FF"
      />
      <path
        d="M115.206 77.1575C115.207 78.3546 115.056 79.547 114.757 80.7061L111.933 77.882C111.053 77.0045 109.904 76.4482 108.67 76.3027C107.436 76.1572 106.188 76.4309 105.129 77.0797C106.661 78.9725 107.533 81.3141 107.612 83.7481C107.691 86.1822 106.973 88.5755 105.567 90.5638C102.968 91.4314 100.173 91.5296 97.519 90.8465L100.343 88.0225C101.221 87.1427 101.777 85.9935 101.922 84.7594C102.068 83.5254 101.794 82.2783 101.145 81.2186C99.4492 82.593 97.3871 83.4397 95.2145 83.6538C94.8434 83.6892 94.4723 83.7069 94.1012 83.7069C91.7931 83.7094 89.5419 82.9915 87.6613 81.6533C86.7937 79.0551 86.6955 76.2615 87.3786 73.6088L90.2026 76.4329C91.0824 77.3104 92.2316 77.8667 93.4656 78.0122C94.6997 78.1577 95.9468 77.8841 97.0065 77.2352C95.4741 75.3424 94.602 73.0009 94.5229 70.5668C94.4437 68.1327 95.162 65.7395 96.5682 63.7512C99.1677 62.8836 101.962 62.7854 104.616 63.4684L101.792 66.2925C100.915 67.1722 100.358 68.3214 100.213 69.5555C100.067 70.7895 100.341 72.0366 100.99 73.0963C102.883 71.5639 105.224 70.6918 107.658 70.6127C110.092 70.5335 112.486 71.2518 114.474 72.6581C114.96 74.1084 115.207 75.628 115.206 77.1575Z"
        fill="#466BE7"
      />
      <path
        d="M101.065 82.8143C99.9468 82.8143 98.8535 82.4826 97.9235 81.8612C96.9935 81.2398 96.2687 80.3566 95.8406 79.3232C95.4126 78.2899 95.3006 77.1528 95.5188 76.0558C95.737 74.9588 96.2756 73.9512 97.0665 73.1603C97.8574 72.3694 98.8651 71.8308 99.9621 71.6126C101.059 71.3944 102.196 71.5064 103.229 71.9344C104.263 72.3624 105.146 73.0872 105.767 74.0172C106.389 74.9472 106.721 76.0406 106.721 77.1591C106.719 78.6584 106.122 80.0958 105.062 81.156C104.002 82.2162 102.565 82.8126 101.065 82.8143Z"
        fill="#C1CCEC"
      />
      <path
        d="M151.962 77.1588C151.959 80.7077 150.62 84.1255 148.212 86.7325C145.804 89.3395 142.503 90.9447 138.966 91.2289C135.428 91.5132 131.914 90.4556 129.12 88.2666C126.327 86.0775 124.46 82.9173 123.89 79.4145C123.321 75.9116 124.09 72.3226 126.046 69.3613C128.002 66.4 131.001 64.2832 134.446 63.432C137.891 62.5807 141.531 63.0574 144.64 64.7671C147.75 66.4768 150.103 69.2943 151.23 72.6594C151.716 74.1098 151.963 75.6293 151.962 77.1588Z"
        fill="#6487FF"
      />
      <path
        d="M151.963 77.1575C151.964 78.3546 151.814 79.547 151.514 80.7061L148.69 77.882C147.811 77.0045 146.661 76.4482 145.427 76.3027C144.193 76.1572 142.946 76.4309 141.887 77.0797C143.419 78.9725 144.291 81.3141 144.37 83.7481C144.449 86.1822 143.731 88.5755 142.325 90.5638C139.725 91.4314 136.931 91.5296 134.277 90.8465L137.101 88.0225C137.978 87.1427 138.535 85.9935 138.68 84.7594C138.826 83.5254 138.552 82.2783 137.903 81.2186C136.207 82.593 134.145 83.4397 131.972 83.6538C131.601 83.6892 131.23 83.7069 130.859 83.7069C128.551 83.7094 126.3 82.9915 124.419 81.6533C123.552 79.0551 123.453 76.2615 124.136 73.6088L126.96 76.4329C127.84 77.3104 128.989 77.8667 130.223 78.0122C131.457 78.1577 132.705 77.8841 133.764 77.2352C132.232 75.3424 131.36 73.0009 131.281 70.5668C131.202 68.1327 131.92 65.7395 133.326 63.7512C135.925 62.8836 138.72 62.7854 141.374 63.4684L138.55 66.2925C137.672 67.1722 137.116 68.3214 136.971 69.5555C136.825 70.7895 137.099 72.0366 137.748 73.0963C139.64 71.5639 141.982 70.6918 144.416 70.6127C146.85 70.5335 149.243 71.2518 151.232 72.6581C151.717 74.1084 151.964 75.628 151.963 77.1575Z"
        fill="#466BE7"
      />
      <path
        d="M137.827 82.8143C136.709 82.8143 135.615 82.4826 134.685 81.8612C133.755 81.2398 133.03 80.3566 132.602 79.3232C132.174 78.2899 132.062 77.1528 132.281 76.0558C132.499 74.9588 133.037 73.9512 133.828 73.1603C134.619 72.3694 135.627 71.8308 136.724 71.6126C137.821 71.3944 138.958 71.5064 139.991 71.9344C141.025 72.3624 141.908 73.0872 142.529 74.0172C143.151 74.9472 143.482 76.0406 143.482 77.1591C143.481 78.6584 142.884 80.0958 141.824 81.156C140.764 82.2162 139.326 82.8126 137.827 82.8143Z"
        fill="#C1CCEC"
      />
    </svg>
  );
};

export default MiningSoftwareIcon;
