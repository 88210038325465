import {
  RustIcon,
  TSIcon,
  JSIcon,
  SolidityIcon,
  NodeJSIcon,
  GolangIcon
} from 'components/icons/promo';

export const stackData = [
  {
    icon: <RustIcon />,
    title: 'promo.stack.rust'
  },
  {
    icon: <TSIcon />,
    title: 'promo.stack.ts'
  },
  {
    icon: <JSIcon />,
    title: 'promo.stack.js'
  },
  {
    icon: <SolidityIcon />,
    title: 'promo.stack.solidity'
  },
  {
    icon: <NodeJSIcon />,
    title: 'promo.stack.nodejs'
  },
  {
    icon: <GolangIcon />,
    title: 'promo.stack.golang'
  }
];
