const ArrowRight = () => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.935088 14.8187L6.75509 8.99875L0.935088 3.17875C0.350088 2.59375 0.350088 1.64875 0.935088 1.06375C1.52009 0.47875 2.46509 0.47875 3.05009 1.06375L9.93509 7.94875C10.5201 8.53375 10.5201 9.47875 9.93509 10.0638L3.05009 16.9487C2.46509 17.5337 1.52009 17.5337 0.935088 16.9487C0.365088 16.3637 0.350088 15.4037 0.935088 14.8187Z"
        fill="#6487FF"
      />
    </svg>
  );
};

export default ArrowRight;
