const DeFiIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M138.375 23.0625H25.625C24.9454 23.0625 24.2936 23.3325 23.813 23.813C23.3325 24.2936 23.0625 24.9454 23.0625 25.625V138.375C23.0625 139.055 23.3325 139.706 23.813 140.187C24.2936 140.668 24.9454 140.937 25.625 140.938H138.375C139.055 140.937 139.706 140.668 140.187 140.187C140.668 139.706 140.937 139.055 140.938 138.375V25.625C140.937 24.9454 140.668 24.2936 140.187 23.813C139.706 23.3325 139.055 23.0625 138.375 23.0625ZM79.4375 75.8135L31.8115 28.1875H79.4375V75.8135ZM84.5625 28.1875H132.188L84.5625 75.8135V28.1875ZM75.8135 79.4375H28.1875V31.8115L75.8135 79.4375ZM28.1875 84.5625H75.8135L28.1875 132.188V84.5625ZM79.4375 88.1865V135.812H31.8115L79.4375 88.1865ZM84.5625 88.1865L132.188 135.812H84.5625V88.1865ZM88.1865 84.5625H135.812V132.188L88.1865 84.5625ZM135.812 79.4375H88.1865L135.812 31.8115V79.4375Z"
        fill="#D3DCFF"
      />
      <path
        d="M99.9375 74.3125V89.6875L82 99.9375L64.0625 89.6875V74.3125H99.9375Z"
        fill="#97ADFF"
      />
      <path
        d="M82 64.0625L64.0625 74.3125L82 84.5625L99.9375 74.3125L82 64.0625Z"
        fill="#4C6BE2"
      />
      <path
        d="M82 99.9375V84.5625L99.9375 74.3125V89.6875L82 99.9375Z"
        fill="#6487FF"
      />
      <path
        d="M81.6908 71.5015L77.5215 73.9351C77.4555 73.9736 77.4008 74.0287 77.3627 74.0949C77.3247 74.1612 77.3047 74.2363 77.3047 74.3126C77.3047 74.389 77.3247 74.4641 77.3627 74.5303C77.4008 74.5966 77.4555 74.6517 77.5215 74.6902L81.6908 77.1238C81.7846 77.1785 81.8911 77.2073 81.9996 77.2073C82.1081 77.2073 82.2147 77.1785 82.3084 77.1238L86.4777 74.6902C86.5437 74.6517 86.5984 74.5966 86.6365 74.5303C86.6745 74.4641 86.6945 74.389 86.6945 74.3126C86.6945 74.2363 86.6745 74.1612 86.6365 74.0949C86.5984 74.0287 86.5437 73.9736 86.4777 73.9351L82.3084 71.5015C82.2147 71.4468 82.1081 71.418 81.9996 71.418C81.8911 71.418 81.7846 71.4468 81.6908 71.5015Z"
        fill="#97ADFF"
      />
      <path
        d="M156.312 74.3125V89.6875L138.375 99.9375L120.438 89.6875V74.3125H156.312Z"
        fill="#97ADFF"
      />
      <path
        d="M138.375 64.0625L120.438 74.3125L138.375 84.5625L156.312 74.3125L138.375 64.0625Z"
        fill="#4C6BE2"
      />
      <path
        d="M138.375 99.9375V84.5625L156.312 74.3125V89.6875L138.375 99.9375Z"
        fill="#6487FF"
      />
      <path
        d="M138.066 71.5015L133.896 73.9351C133.831 73.9736 133.776 74.0287 133.738 74.0949C133.7 74.1612 133.68 74.2363 133.68 74.3126C133.68 74.389 133.7 74.4641 133.738 74.5303C133.776 74.5966 133.831 74.6517 133.896 74.6902L138.066 77.1238C138.16 77.1785 138.266 77.2073 138.375 77.2073C138.483 77.2073 138.59 77.1785 138.683 77.1238L142.853 74.6902C142.919 74.6517 142.973 74.5966 143.011 74.5303C143.05 74.4641 143.07 74.389 143.07 74.3126C143.07 74.2363 143.05 74.1612 143.011 74.0949C142.973 74.0287 142.919 73.9736 142.853 73.9351L138.683 71.5015C138.59 71.4468 138.483 71.418 138.375 71.418C138.266 71.418 138.16 71.4468 138.066 71.5015Z"
        fill="#97ADFF"
      />
      <path
        d="M43.5625 74.3125V89.6875L25.625 99.9375L7.6875 89.6875V74.3125H43.5625Z"
        fill="#97ADFF"
      />
      <path
        d="M25.625 64.0625L7.6875 74.3125L25.625 84.5625L43.5625 74.3125L25.625 64.0625Z"
        fill="#4C6BE2"
      />
      <path
        d="M25.625 99.9375V84.5625L43.5625 74.3125V89.6875L25.625 99.9375Z"
        fill="#6487FF"
      />
      <path
        d="M25.3159 71.5015L21.1465 73.9351C21.0805 73.9736 21.0258 74.0287 20.9877 74.0949C20.9497 74.1612 20.9297 74.2363 20.9297 74.3126C20.9297 74.389 20.9497 74.4641 20.9877 74.5303C21.0258 74.5966 21.0805 74.6517 21.1465 74.6902L25.3159 77.1238C25.4096 77.1785 25.5161 77.2073 25.6246 77.2073C25.7331 77.2073 25.8397 77.1785 25.9334 77.1238L30.1027 74.6902C30.1687 74.6517 30.2234 74.5966 30.2615 74.5303C30.2995 74.4641 30.3195 74.389 30.3195 74.3126C30.3195 74.2363 30.2995 74.1612 30.2615 74.0949C30.2234 74.0287 30.1687 73.9736 30.1027 73.9351L25.9334 71.5015C25.8397 71.4468 25.7331 71.418 25.6246 71.418C25.5161 71.418 25.4096 71.4468 25.3159 71.5015Z"
        fill="#97ADFF"
      />
      <path
        d="M43.5625 17.9375V33.3125L25.625 43.5625L7.6875 33.3125V17.9375H43.5625Z"
        fill="#97ADFF"
      />
      <path
        d="M25.625 7.6875L7.6875 17.9375L25.625 28.1875L43.5625 17.9375L25.625 7.6875Z"
        fill="#4C6BE2"
      />
      <path
        d="M25.625 43.5625V28.1875L43.5625 17.9375V33.3125L25.625 43.5625Z"
        fill="#6487FF"
      />
      <path
        d="M25.3159 15.1265L21.1465 17.5601C21.0805 17.5986 21.0258 17.6537 20.9877 17.72C20.9497 17.7862 20.9297 17.8613 20.9297 17.9377C20.9297 18.014 20.9497 18.0891 20.9877 18.1553C21.0258 18.2216 21.0805 18.2767 21.1465 18.3152L25.3159 20.7488C25.4096 20.8035 25.5161 20.8323 25.6246 20.8323C25.7331 20.8323 25.8397 20.8035 25.9334 20.7488L30.1027 18.3152C30.1687 18.2767 30.2234 18.2216 30.2615 18.1553C30.2995 18.0891 30.3195 18.014 30.3195 17.9377C30.3195 17.8613 30.2995 17.7862 30.2615 17.72C30.2234 17.6537 30.1687 17.5986 30.1027 17.5601L25.9334 15.1265C25.8397 15.0718 25.7331 15.043 25.6246 15.043C25.5161 15.043 25.4096 15.0718 25.3159 15.1265Z"
        fill="#97ADFF"
      />
      <path
        d="M99.9375 17.9375V33.3125L82 43.5625L64.0625 33.3125V17.9375H99.9375Z"
        fill="#97ADFF"
      />
      <path
        d="M82 7.6875L64.0625 17.9375L82 28.1875L99.9375 17.9375L82 7.6875Z"
        fill="#4C6BE2"
      />
      <path
        d="M82 43.5625V28.1875L99.9375 17.9375V33.3125L82 43.5625Z"
        fill="#6487FF"
      />
      <path
        d="M81.6908 15.1265L77.5215 17.5601C77.4555 17.5986 77.4008 17.6537 77.3627 17.72C77.3247 17.7862 77.3047 17.8613 77.3047 17.9377C77.3047 18.014 77.3247 18.0891 77.3627 18.1553C77.4008 18.2216 77.4555 18.2767 77.5215 18.3152L81.6908 20.7488C81.7846 20.8035 81.8911 20.8323 81.9996 20.8323C82.1081 20.8323 82.2147 20.8035 82.3084 20.7488L86.4777 18.3152C86.5437 18.2767 86.5984 18.2216 86.6365 18.1553C86.6745 18.0891 86.6945 18.014 86.6945 17.9377C86.6945 17.8613 86.6745 17.7862 86.6365 17.72C86.5984 17.6537 86.5437 17.5986 86.4777 17.5601L82.3084 15.1265C82.2147 15.0718 82.1081 15.043 81.9996 15.043C81.8911 15.043 81.7846 15.0718 81.6908 15.1265Z"
        fill="#97ADFF"
      />
      <path
        d="M156.312 17.9375V33.3125L138.375 43.5625L120.438 33.3125V17.9375H156.312Z"
        fill="#97ADFF"
      />
      <path
        d="M138.375 7.6875L120.438 17.9375L138.375 28.1875L156.312 17.9375L138.375 7.6875Z"
        fill="#4C6BE2"
      />
      <path
        d="M138.375 43.5625V28.1875L156.312 17.9375V33.3125L138.375 43.5625Z"
        fill="#6487FF"
      />
      <path
        d="M138.066 15.1265L133.896 17.5601C133.831 17.5986 133.776 17.6537 133.738 17.72C133.7 17.7862 133.68 17.8613 133.68 17.9377C133.68 18.014 133.7 18.0891 133.738 18.1553C133.776 18.2216 133.831 18.2767 133.896 18.3152L138.066 20.7488C138.16 20.8035 138.266 20.8323 138.375 20.8323C138.483 20.8323 138.59 20.8035 138.683 20.7488L142.853 18.3152C142.919 18.2767 142.973 18.2216 143.011 18.1553C143.05 18.0891 143.07 18.014 143.07 17.9377C143.07 17.8613 143.05 17.7862 143.011 17.72C142.973 17.6537 142.919 17.5986 142.853 17.5601L138.683 15.1265C138.59 15.0718 138.483 15.043 138.375 15.043C138.266 15.043 138.16 15.0718 138.066 15.1265Z"
        fill="#97ADFF"
      />
      <path
        d="M43.5625 130.688V146.062L25.625 156.312L7.6875 146.062V130.688H43.5625Z"
        fill="#97ADFF"
      />
      <path
        d="M25.625 120.438L7.6875 130.688L25.625 140.938L43.5625 130.688L25.625 120.438Z"
        fill="#4C6BE2"
      />
      <path
        d="M25.625 156.312V140.938L43.5625 130.688V146.062L25.625 156.312Z"
        fill="#6487FF"
      />
      <path
        d="M25.3159 127.876L21.1465 130.31C21.0805 130.349 21.0258 130.404 20.9877 130.47C20.9497 130.536 20.9297 130.611 20.9297 130.688C20.9297 130.764 20.9497 130.839 20.9877 130.905C21.0258 130.972 21.0805 131.027 21.1465 131.065L25.3159 133.499C25.4096 133.554 25.5161 133.582 25.6246 133.582C25.7331 133.582 25.8397 133.554 25.9334 133.499L30.1027 131.065C30.1687 131.027 30.2234 130.972 30.2615 130.905C30.2995 130.839 30.3195 130.764 30.3195 130.688C30.3195 130.611 30.2995 130.536 30.2615 130.47C30.2234 130.404 30.1687 130.349 30.1027 130.31L25.9334 127.876C25.8397 127.822 25.7331 127.793 25.6246 127.793C25.5161 127.793 25.4096 127.822 25.3159 127.876Z"
        fill="#97ADFF"
      />
      <path
        d="M99.9375 130.688V146.062L82 156.312L64.0625 146.062V130.688H99.9375Z"
        fill="#97ADFF"
      />
      <path
        d="M82 120.438L64.0625 130.688L82 140.938L99.9375 130.688L82 120.438Z"
        fill="#4C6BE2"
      />
      <path
        d="M82 156.312V140.938L99.9375 130.688V146.062L82 156.312Z"
        fill="#6487FF"
      />
      <path
        d="M81.6908 127.876L77.5215 130.31C77.4555 130.349 77.4008 130.404 77.3627 130.47C77.3247 130.536 77.3047 130.611 77.3047 130.688C77.3047 130.764 77.3247 130.839 77.3627 130.905C77.4008 130.972 77.4555 131.027 77.5215 131.065L81.6908 133.499C81.7846 133.554 81.8911 133.582 81.9996 133.582C82.1081 133.582 82.2147 133.554 82.3084 133.499L86.4777 131.065C86.5437 131.027 86.5984 130.972 86.6365 130.905C86.6745 130.839 86.6945 130.764 86.6945 130.688C86.6945 130.611 86.6745 130.536 86.6365 130.47C86.5984 130.404 86.5437 130.349 86.4777 130.31L82.3084 127.876C82.2147 127.822 82.1081 127.793 81.9996 127.793C81.8911 127.793 81.7846 127.822 81.6908 127.876Z"
        fill="#97ADFF"
      />
      <path
        d="M156.312 130.688V146.062L138.375 156.312L120.438 146.062V130.688H156.312Z"
        fill="#97ADFF"
      />
      <path
        d="M138.375 120.438L120.438 130.688L138.375 140.938L156.312 130.688L138.375 120.438Z"
        fill="#4C6BE2"
      />
      <path
        d="M138.375 156.312V140.938L156.312 130.688V146.062L138.375 156.312Z"
        fill="#6487FF"
      />
      <path
        d="M138.066 127.876L133.896 130.31C133.831 130.349 133.776 130.404 133.738 130.47C133.7 130.536 133.68 130.611 133.68 130.688C133.68 130.764 133.7 130.839 133.738 130.905C133.776 130.972 133.831 131.027 133.896 131.065L138.066 133.499C138.16 133.554 138.266 133.582 138.375 133.582C138.483 133.582 138.59 133.554 138.683 133.499L142.853 131.065C142.919 131.027 142.973 130.972 143.011 130.905C143.05 130.839 143.07 130.764 143.07 130.688C143.07 130.611 143.05 130.536 143.011 130.47C142.973 130.404 142.919 130.349 142.853 130.31L138.683 127.876C138.59 127.822 138.483 127.793 138.375 127.793C138.266 127.793 138.16 127.822 138.066 127.876Z"
        fill="#97ADFF"
      />
    </svg>
  );
};

export default DeFiIcon;
