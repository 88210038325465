const CustomSolutionsIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.438 58.9375V123L66.625 156.312L56.375 149.967L51.25 146.796L12.8125 123V58.9375L66.625 25.625L120.438 58.9375Z"
        fill="#6487FF"
      />
      <path
        d="M120.438 58.9375L66.625 92.25L43.8508 78.153L39.0685 75.1902L12.8125 58.9375L66.625 25.625L120.438 58.9375Z"
        fill="#D3DCFF"
      />
      <path
        d="M66.625 156.312V92.25L120.438 58.9375V123L66.625 156.312Z"
        fill="#97ADFF"
      />
      <path
        d="M94.8125 58.9375L66.625 76.875L56.6088 70.5008L51.7721 67.4226L38.4375 58.9375L66.625 41L94.8125 58.9375Z"
        fill="#6487FF"
      />
      <path
        d="M153.75 128.125V112.75H144.888C144.435 111.315 143.856 109.922 143.157 108.589L149.429 102.318L138.557 91.4461L132.286 97.7177C130.953 97.019 129.56 96.44 128.125 95.9873V87.125H112.75V95.9874C111.315 96.44 109.922 97.019 108.589 97.7177L102.318 91.4461L91.4461 102.318L97.7177 108.589C97.019 109.922 96.44 111.315 95.9873 112.75H87.125V128.125H95.9874C96.44 129.56 97.019 130.953 97.7177 132.286L91.4461 138.557L102.318 149.429L108.589 143.157C109.922 143.856 111.315 144.435 112.75 144.888V153.75H128.125V144.888C129.56 144.435 130.953 143.856 132.286 143.157L138.557 149.429L149.429 138.557L143.157 132.286C143.856 130.953 144.435 129.56 144.888 128.125H153.75ZM120.438 133.25C117.903 133.25 115.426 132.499 113.319 131.091C111.212 129.683 109.57 127.682 108.6 125.341C107.631 122.999 107.377 120.423 107.871 117.938C108.366 115.453 109.586 113.17 111.378 111.378C113.17 109.586 115.453 108.366 117.938 107.871C120.423 107.377 122.999 107.631 125.341 108.6C127.682 109.57 129.683 111.212 131.091 113.319C132.499 115.426 133.25 117.903 133.25 120.438C133.25 123.836 131.9 127.094 129.497 129.497C127.094 131.9 123.836 133.25 120.438 133.25Z"
        fill="#D3DCFF"
      />
      <path
        d="M133.251 58.9365H128.126V50.2702L114.168 43.2909L116.46 38.707L131.835 46.3945C132.261 46.6073 132.619 46.9345 132.869 47.3393C133.119 47.7441 133.251 48.2106 133.251 48.6865V58.9365Z"
        fill="#97ADFF"
      />
      <path
        d="M128.125 64.0625H133.25V79.4375H128.125V64.0625Z"
        fill="#97ADFF"
      />
      <path
        d="M143.5 48.6875H138.375V42.5837L124.417 35.6044C123.991 35.3916 123.633 35.0645 123.383 34.6597C123.133 34.2549 123 33.7884 123 33.3125V23.0625H128.125V31.7288L142.083 38.7081C142.509 38.9209 142.867 39.248 143.117 39.6528C143.367 40.0576 143.5 40.5241 143.5 41V48.6875Z"
        fill="#97ADFF"
      />
      <path d="M138.375 53.8125H143.5V82H138.375V53.8125Z" fill="#97ADFF" />
      <path d="M148.625 35.875H153.75V89.6875H148.625V35.875Z" fill="#97ADFF" />
      <path d="M148.625 17.9375H153.75V30.75H148.625V17.9375Z" fill="#97ADFF" />
      <path
        d="M56.375 130.689V149.968L51.25 146.797V132.14L39.6803 125.199C39.3011 124.971 38.9874 124.649 38.7696 124.264C38.5518 123.879 38.4374 123.444 38.4375 123.001V111.64L26.8678 104.699C26.4886 104.471 26.1749 104.149 25.9571 103.764C25.7393 103.379 25.6249 102.944 25.625 102.501V84.5637C25.6253 84.1103 25.7458 83.6649 25.9744 83.2733C26.2029 82.8816 26.5313 82.5575 26.926 82.3341C27.3206 82.1107 27.7675 81.996 28.2209 82.0017C28.6744 82.0074 29.1182 82.1332 29.5072 82.3664L38.4375 87.7252V76.8762C38.4375 76.2569 38.6616 75.6584 39.0685 75.1914L43.8508 78.1543L43.5625 78.3273V92.2512C43.5622 92.7047 43.4417 93.1501 43.2131 93.5417C42.9846 93.9334 42.6562 94.2575 42.2615 94.4809C41.8669 94.7043 41.42 94.819 40.9666 94.8133C40.5131 94.8076 40.0693 94.6818 39.6803 94.4486L30.75 89.0898V101.05L42.3197 107.991C42.6989 108.219 43.0126 108.541 43.2304 108.926C43.4482 109.311 43.5626 109.746 43.5625 110.189V121.55L55.1322 128.491C55.5114 128.719 55.8251 129.041 56.0429 129.426C56.2607 129.811 56.3751 130.246 56.375 130.689Z"
        fill="#466BE7"
      />
      <path
        d="M56.6106 70.5001L43.8526 78.1523L39.0703 75.1895C39.2461 74.9876 39.4526 74.8147 39.6821 74.677L51.7739 67.4219L56.6106 70.5001Z"
        fill="#6487FF"
      />
      <path
        d="M112.75 43.5625C115.58 43.5625 117.875 41.268 117.875 38.4375C117.875 35.607 115.58 33.3125 112.75 33.3125C109.92 33.3125 107.625 35.607 107.625 38.4375C107.625 41.268 109.92 43.5625 112.75 43.5625Z"
        fill="#97ADFF"
      />
      <path
        d="M112.75 46.125C111.23 46.125 109.743 45.6741 108.479 44.8294C107.215 43.9847 106.23 42.7841 105.648 41.3794C105.066 39.9747 104.914 38.429 105.21 36.9377C105.507 35.4465 106.239 34.0767 107.314 33.0016C108.389 31.9265 109.759 31.1943 111.25 30.8977C112.741 30.6011 114.287 30.7533 115.692 31.3352C117.097 31.917 118.297 32.9024 119.142 34.1666C119.987 35.4308 120.438 36.9171 120.438 38.4375C120.435 40.4757 119.625 42.4297 118.183 43.8709C116.742 45.3121 114.788 46.1227 112.75 46.125ZM112.75 35.875C112.243 35.875 111.748 36.0253 111.326 36.3069C110.905 36.5884 110.577 36.9886 110.383 37.4569C110.189 37.9251 110.138 38.4403 110.237 38.9374C110.336 39.4345 110.58 39.8911 110.938 40.2495C111.296 40.6078 111.753 40.8519 112.25 40.9508C112.747 41.0496 113.262 40.9989 113.731 40.8049C114.199 40.611 114.599 40.2826 114.881 39.8612C115.162 39.4398 115.313 38.9443 115.313 38.4375C115.312 37.7581 115.042 37.1068 114.561 36.6264C114.081 36.146 113.429 35.8758 112.75 35.875Z"
        fill="#D3DCFF"
      />
      <path
        d="M125.562 23.0625C128.393 23.0625 130.688 20.768 130.688 17.9375C130.688 15.107 128.393 12.8125 125.562 12.8125C122.732 12.8125 120.438 15.107 120.438 17.9375C120.438 20.768 122.732 23.0625 125.562 23.0625Z"
        fill="#97ADFF"
      />
      <path
        d="M125.563 25.625C124.042 25.625 122.556 25.1741 121.292 24.3294C120.027 23.4847 119.042 22.2841 118.46 20.8794C117.878 19.4747 117.726 17.929 118.023 16.4377C118.319 14.9465 119.052 13.5767 120.127 12.5016C121.202 11.4265 122.572 10.6943 124.063 10.3977C125.554 10.1011 127.1 10.2533 128.504 10.8352C129.909 11.417 131.11 12.4024 131.954 13.6666C132.799 14.9308 133.25 16.4171 133.25 17.9375C133.248 19.9757 132.437 21.9297 130.996 23.3709C129.555 24.8121 127.601 25.6227 125.563 25.625ZM125.563 15.375C125.056 15.375 124.56 15.5253 124.139 15.8069C123.717 16.0884 123.389 16.4886 123.195 16.9569C123.001 17.4251 122.95 17.9403 123.049 18.4374C123.148 18.9345 123.392 19.3911 123.751 19.7495C124.109 20.1078 124.566 20.3519 125.063 20.4508C125.56 20.5496 126.075 20.4989 126.543 20.3049C127.011 20.111 127.412 19.7826 127.693 19.3612C127.975 18.9398 128.125 18.4443 128.125 17.9375C128.124 17.2581 127.854 16.6068 127.374 16.1264C126.893 15.646 126.242 15.3758 125.563 15.375Z"
        fill="#D3DCFF"
      />
      <path
        d="M151.188 17.9375C154.018 17.9375 156.312 15.643 156.312 12.8125C156.312 9.98204 154.018 7.6875 151.188 7.6875C148.357 7.6875 146.062 9.98204 146.062 12.8125C146.062 15.643 148.357 17.9375 151.188 17.9375Z"
        fill="#97ADFF"
      />
      <path
        d="M151.188 20.5C149.667 20.5 148.181 20.0491 146.917 19.2044C145.652 18.3597 144.667 17.1591 144.085 15.7544C143.503 14.3497 143.351 12.804 143.648 11.3127C143.944 9.82152 144.677 8.45174 145.752 7.37662C146.827 6.3015 148.197 5.56934 149.688 5.27272C151.179 4.97609 152.725 5.12833 154.129 5.71018C155.534 6.29203 156.735 7.27735 157.579 8.54156C158.424 9.80576 158.875 11.2921 158.875 12.8125C158.873 14.8507 158.062 16.8047 156.621 18.2459C155.18 19.6871 153.226 20.4977 151.188 20.5ZM151.188 10.25C150.681 10.25 150.185 10.4003 149.764 10.6819C149.342 10.9634 149.014 11.3636 148.82 11.8319C148.626 12.3001 148.575 12.8153 148.674 13.3124C148.773 13.8095 149.017 14.2661 149.376 14.6245C149.734 14.9828 150.191 15.2269 150.688 15.3258C151.185 15.4246 151.7 15.3739 152.168 15.1799C152.636 14.986 153.037 14.6576 153.318 14.2361C153.6 13.8147 153.75 13.3193 153.75 12.8125C153.749 12.1331 153.479 11.4818 152.999 11.0014C152.518 10.521 151.867 10.2508 151.188 10.25Z"
        fill="#D3DCFF"
      />
    </svg>
  );
};

export default CustomSolutionsIcon;
