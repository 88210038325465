import { MinerallGuildIcon, NearGuildIcon } from 'components/icons/promo';

export const topData = [
  {
    icon: <MinerallGuildIcon />,
    title: 'promo.minerallGuild',
    description: 'promo.minerallGuildInfo',
    isAccented: true
  },
  {
    icon: <NearGuildIcon />,
    title: 'promo.nearGuilds',
    description: 'promo.nearGuildsInfo',
    isAccented: false
  }
];
