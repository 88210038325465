import { useTranslation } from 'react-i18next';

import { Item, AccentText } from './stack-item.styles';

const StackItem = ({ icon, title }) => {
  const { t } = useTranslation();

  return (
    <Item>
      {icon}
      <AccentText size="up" weight="bold" color="primary.regular">
        {t(`${title}`)}
      </AccentText>
    </Item>
  );
};

export default StackItem;
