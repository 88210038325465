const DAppsIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2085_1846)">
        <path
          d="M29.1882 105.629H68.1035C71.1736 105.629 73.6628 108.118 73.6628 111.188V136.205H23.6289V111.188C23.6289 108.118 26.1181 105.629 29.1882 105.629Z"
          fill="#4C6BE2"
        />
        <path
          d="M23.6289 136.203H73.6628V144.542C73.6628 147.612 71.1736 150.101 68.1035 150.101H29.1882C26.1181 150.101 23.6289 147.612 23.6289 144.542V136.203Z"
          fill="#D3DCFF"
        />
        <path
          d="M45.8633 150.102H51.4226V161.22H45.8633V150.102Z"
          fill="#97ADFF"
        />
        <path
          d="M37.5273 158.441H59.7646V164.001H37.5273V158.441Z"
          fill="#D3DCFF"
        />
        <rect
          x="91.5664"
          y="34.168"
          width="36.9"
          height="28.7"
          rx="2"
          fill="#D3DCFF"
        />
        <rect
          x="21.8672"
          y="31.4336"
          width="36.9"
          height="31.4333"
          rx="2"
          fill="#97ADFF"
        />
        <path
          d="M46.4648 11.5664C46.4648 10.4618 47.3603 9.56641 48.4648 9.56641H104.598C105.703 9.56641 106.598 10.4618 106.598 11.5664V62.8664H46.4648V11.5664Z"
          fill="#D3DCFF"
        />
        <path
          d="M95.8992 102.848H123.696C126.766 102.848 129.255 105.337 129.255 108.407V147.322H90.3398V108.407C90.3398 105.337 92.8291 102.848 95.8992 102.848Z"
          fill="#4C6BE2"
        />
        <path
          d="M84.7794 48.4065L91.1531 54.7802L95.0837 50.8496L81.9997 37.7656L68.9158 50.8496L72.8464 54.7802L79.2201 48.4065V83.3911H54.2031V88.9505H109.796V83.3911H84.7794V48.4065Z"
          fill="#97ADFF"
        />
        <path
          d="M45.8633 91.7305H51.4226V105.629H45.8633V91.7305Z"
          fill="#97ADFF"
        />
        <path
          d="M112.574 91.7305H118.134V102.849H112.574V91.7305Z"
          fill="#97ADFF"
        />
        <path
          d="M90.3398 147.32H129.255V155.659C129.255 158.729 126.766 161.219 123.696 161.219H95.8992C92.8291 161.219 90.3398 158.729 90.3398 155.659V147.32Z"
          fill="#D3DCFF"
        />
        <path
          d="M54.2046 86.1687C54.2046 89.2388 51.7154 91.728 48.6453 91.728C45.5751 91.728 43.0859 89.2388 43.0859 86.1687C43.0859 83.0986 45.5751 80.6094 48.6453 80.6094C51.7154 80.6094 54.2046 83.0986 54.2046 86.1687Z"
          fill="#97ADFF"
        />
        <path
          d="M48.6437 94.51C44.0385 94.51 40.3047 90.7762 40.3047 86.171C40.3047 81.5658 44.0385 77.832 48.6437 77.832C53.2488 77.832 56.9827 81.5658 56.9827 86.171C56.9772 90.7748 53.2475 94.5046 48.6437 94.51ZM48.6437 83.3914C47.1086 83.3914 45.864 84.636 45.864 86.171C45.864 87.7061 47.1086 88.9507 48.6437 88.9507C50.1787 88.9507 51.4233 87.7061 51.4233 86.171C51.422 84.636 50.1787 83.3927 48.6437 83.3914Z"
          fill="#D3DCFF"
        />
        <path
          d="M120.916 86.1687C120.916 89.2388 118.426 91.728 115.356 91.728C112.286 91.728 109.797 89.2388 109.797 86.1687C109.797 83.0986 112.286 80.6094 115.356 80.6094C118.426 80.6094 120.916 83.0986 120.916 86.1687Z"
          fill="#97ADFF"
        />
        <path
          d="M115.355 94.51C110.749 94.51 107.016 90.7762 107.016 86.171C107.016 81.5658 110.749 77.832 115.355 77.832C119.96 77.832 123.694 81.5658 123.694 86.171C123.688 90.7748 119.958 94.5046 115.355 94.51ZM115.355 83.3914C113.82 83.3914 112.575 84.636 112.575 86.171C112.575 87.7061 113.82 88.9507 115.355 88.9507C116.89 88.9507 118.134 87.7061 118.134 86.171C118.133 84.636 116.89 83.3927 115.355 83.3914Z"
          fill="#D3DCFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2085_1846">
          <rect width="164" height="164" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DAppsIcon;
