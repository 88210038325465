import { useTranslation } from 'react-i18next';

import { ArrowRight } from 'components/icons/promo';
import { Button } from 'components/common/button/button';
import {
  Container,
  Title,
  Content,
  Anchor,
  ArrowContainer
} from './start.styles';

const Start = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title weight="bold" mb={3}>
        {t('promo.start.title')}
      </Title>
      <Content weight="bold">
        <span>{t('promo.start.steps.requirements')}</span>

        <ArrowContainer>
          <ArrowRight />
        </ArrowContainer>

        <span>{t('promo.start.steps.productDevelopment')}</span>

        <ArrowContainer>
          <ArrowRight />
        </ArrowContainer>

        <span>{t('promo.start.steps.productDelivery')}</span>
      </Content>

      <p>{t('promo.start.getInTouch')}</p>

      <Button>
        <Anchor
          href="https://docs.google.com/forms/d/e/1FAIpQLSeobmx4wKkhIsf3_SXHJm8NQJ3PtNX8JyPG3CwmmtyWQJgWbQ/viewform"
          target="_blank"
        >
          {t('promo.start.contact')}
        </Anchor>
      </Button>
    </Container>
  );
};

export default Start;
