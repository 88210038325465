const NearGuildIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="175"
      height="44"
      viewBox="0 0 175 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.5176 0.638122V43.3904C36.5176 43.5524 36.4538 43.7078 36.3401 43.8223C36.2265 43.9368 36.0724 44.0012 35.9117 44.0012H31.4644C30.4533 44.0004 29.4585 43.7446 28.5706 43.2571C27.6827 42.7696 26.9299 42.0659 26.3809 41.2101L6.35589 10.0131L7.04056 25.5933V43.3904C7.04056 43.5524 6.97672 43.7078 6.86309 43.8223C6.74947 43.9368 6.59535 44.0012 6.43466 44.0012H0.6059C0.445205 44.0012 0.291092 43.9368 0.177463 43.8223C0.0638352 43.7078 0 43.5524 0 43.3904V0.638122C0 0.476141 0.0638352 0.320795 0.177463 0.206258C0.291092 0.0917202 0.445205 0.0273743 0.6059 0.0273743H4.99261C6.00416 0.0264418 6.99985 0.280809 7.88885 0.767273C8.77786 1.25374 9.53189 1.95681 10.0822 2.81238L30.0769 33.9605L29.471 18.4475V0.638122C29.471 0.476141 29.5348 0.320795 29.6484 0.206258C29.7621 0.0917202 29.9162 0.0273743 30.0769 0.0273743H35.9056C35.9857 0.0265671 36.0651 0.0417708 36.1394 0.0721021C36.2136 0.102433 36.2811 0.147292 36.338 0.204086C36.3949 0.26088 36.4401 0.328478 36.4709 0.402978C36.5017 0.477478 36.5176 0.557401 36.5176 0.638122Z"
        fill={color}
      />
      <path
        d="M96.1515 43.9982H89.9713C89.8731 43.9979 89.7764 43.9736 89.6895 43.9272C89.6027 43.8809 89.5284 43.814 89.4729 43.7322C89.4175 43.6505 89.3826 43.5564 89.3712 43.458C89.3599 43.3597 89.3724 43.26 89.4078 43.1676L105.767 0.610747C105.829 0.433522 105.944 0.27986 106.096 0.170603C106.247 0.0613463 106.429 0.00178416 106.615 0H114.341C114.527 0.00178416 114.708 0.0613463 114.86 0.170603C115.012 0.27986 115.127 0.433522 115.189 0.610747L131.548 43.1676C131.584 43.2605 131.596 43.3607 131.585 43.4595C131.573 43.5584 131.537 43.6529 131.481 43.7347C131.425 43.8166 131.35 43.8834 131.262 43.9294C131.175 43.9753 131.077 43.9989 130.979 43.9982H124.78C124.657 43.9974 124.538 43.959 124.437 43.8881C124.337 43.8172 124.26 43.7172 124.217 43.6012L111.032 8.57489C110.989 8.45901 110.911 8.35925 110.81 8.28885C110.709 8.21845 110.589 8.18074 110.466 8.18074C110.343 8.18074 110.223 8.21845 110.122 8.28885C110.02 8.35925 109.943 8.45901 109.899 8.57489L96.715 43.6073C96.671 43.7221 96.5937 43.8208 96.4932 43.8906C96.3926 43.9603 96.2735 43.9978 96.1515 43.9982Z"
        fill={color}
      />
      <path
        d="M174.372 43.0078L162.108 27.2261C169.028 25.9069 173.081 21.1919 173.081 13.9668C173.081 5.67896 167.719 0.0234375 158.249 0.0234375H141.174C140.933 0.0234375 140.702 0.119956 140.532 0.291762C140.361 0.463568 140.266 0.696588 140.266 0.939558C140.266 2.49619 140.879 3.98906 141.971 5.08977C143.063 6.19047 144.544 6.80884 146.088 6.80884H157.528C163.266 6.80884 166.01 9.75875 166.01 14.034C166.01 18.3092 163.332 21.3202 157.528 21.3202H141.174C140.933 21.3202 140.702 21.4167 140.532 21.5885C140.361 21.7603 140.266 21.9933 140.266 22.2363V43.3865C140.266 43.5485 140.329 43.7038 140.443 43.8183C140.557 43.9329 140.711 43.9972 140.872 43.9972H146.7C146.861 43.9972 147.015 43.9329 147.129 43.8183C147.242 43.7038 147.306 43.5485 147.306 43.3865V27.5987H154.038L164.683 41.6031C165.249 42.3477 165.977 42.951 166.812 43.3662C167.646 43.7814 168.564 43.9973 169.494 43.9972H173.893C174.007 43.9979 174.119 43.966 174.216 43.9053C174.313 43.8446 174.391 43.7576 174.441 43.6542C174.491 43.5508 174.511 43.4353 174.499 43.321C174.486 43.2066 174.442 43.0981 174.372 43.0078Z"
        fill={color}
      />
      <path
        d="M77.6939 0.023468H50.6283C50.413 0.023468 50.2065 0.109693 50.0542 0.263173C49.9019 0.416653 49.8164 0.624813 49.8164 0.841867C49.818 2.42497 50.443 3.94269 51.5541 5.06154C52.6653 6.1804 54.1716 6.80887 55.7421 6.80887H77.6999C77.8606 6.80887 78.0147 6.74452 78.1284 6.62999C78.242 6.51545 78.3058 6.3601 78.3058 6.19812V0.634215C78.3058 0.553494 78.2899 0.473572 78.2591 0.399072C78.2283 0.324571 78.1831 0.256974 78.1262 0.20018C78.0693 0.143386 78.0018 0.0985271 77.9276 0.0681958C77.8534 0.0378645 77.7739 0.0226608 77.6939 0.023468ZM77.6939 37.2119H57.4689C57.3082 37.2119 57.1541 37.1475 57.0405 37.033C56.9269 36.9184 56.863 36.7631 56.863 36.6011V25.571C56.863 25.409 56.9269 25.2537 57.0405 25.1392C57.1541 25.0246 57.3082 24.9603 57.4689 24.9603H76.1367C76.2974 24.9603 76.4515 24.8959 76.5651 24.7814C76.6788 24.6669 76.7426 24.5115 76.7426 24.3495V18.7917C76.7426 18.6297 76.6788 18.4744 76.5651 18.3599C76.4515 18.2453 76.2974 18.181 76.1367 18.181H50.7253C50.4842 18.181 50.253 18.2775 50.0826 18.4493C49.9122 18.6211 49.8164 18.8541 49.8164 19.0971V43.0811C49.8164 43.3241 49.9122 43.5571 50.0826 43.7289C50.253 43.9007 50.4842 43.9973 50.7253 43.9973H77.6939C77.8545 43.9973 78.0087 43.9329 78.1223 43.8184C78.2359 43.7038 78.2998 43.5485 78.2998 43.3865V37.8226C78.2998 37.6606 78.2359 37.5053 78.1223 37.3907C78.0087 37.2762 77.8545 37.2119 77.6939 37.2119Z"
        fill={color}
      />
    </svg>
  );
};

export default NearGuildIcon;
