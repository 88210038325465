import styled from 'styled-components';

import { Text } from 'components/common/text/text';

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 105px;

  &:last-of-type {
    justify-content: flex-end;

    svg {
      margin-bottom: 25px;
    }
  }
`;

export const AccentText = styled(Text)`
  text-transform: uppercase;
`;
