import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Container = styled.div`
  text-align: center;

  & > p {
    margin-bottom: 60px;
  }
`;

export const Title = styled(Text)`
  font-size: ${themeGet('fontSizes.mdUp')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.lg')}px;
  }
`;

export const TechnologiesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    gap: 40px;
  }
`;
