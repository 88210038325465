const NFTMarketplacesIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.4667 2.73438H117.533C120.552 2.73438 123 5.1821 123 8.20104V133.934H41V8.20104C41 5.1821 43.4477 2.73438 46.4667 2.73438Z"
        fill="#6487FF"
      />
      <path
        d="M41 133.934H123V155.8C123 158.819 120.552 161.267 117.533 161.267H46.4667C43.4477 161.267 41 158.819 41 155.8V133.934Z"
        fill="#D3DCFF"
      />
      <path d="M54.668 144.867H65.6013V150.334H54.668V144.867Z" fill="white" />
      <path
        d="M98.4023 144.867H109.336V150.334H98.4023V144.867Z"
        fill="white"
      />
      <path
        d="M87.4685 147.599C87.4685 150.618 85.0208 153.066 82.0018 153.066C78.9829 153.066 76.5352 150.618 76.5352 147.599C76.5352 144.581 78.9829 142.133 82.0018 142.133C85.0208 142.133 87.4685 144.581 87.4685 147.599Z"
        fill="white"
      />
      <path
        d="M49.1995 68.3336H40.9995C36.4724 68.3282 32.8048 64.6607 32.7995 60.1336C32.7982 58.6241 31.5756 57.4016 30.0662 57.4003H19.1328V51.9336H30.0662C34.5932 51.9389 38.2608 55.6065 38.2662 60.1336C38.2675 61.6431 39.49 62.8656 40.9995 62.8669H49.1995V68.3336Z"
        fill="#97ADFF"
      />
      <path
        d="M13.668 73.8008H51.9346V79.2674H13.668V73.8008Z"
        fill="#97ADFF"
      />
      <path
        d="M10.9326 49.1992H16.3992C17.9087 49.1992 19.1326 50.4231 19.1326 51.9326V57.3992C19.1326 58.9087 17.9087 60.1326 16.3992 60.1326H10.9326C9.42308 60.1326 8.19922 58.9087 8.19922 57.3992V51.9326C8.19922 50.4231 9.42308 49.1992 10.9326 49.1992Z"
        fill="#97ADFF"
      />
      <path
        d="M16.3982 62.8648H10.9315C7.9139 62.8608 5.46885 60.4158 5.46484 57.3982V51.9315C5.46885 48.9139 7.9139 46.4688 10.9315 46.4648H16.3982C19.4158 46.4688 21.8608 48.9139 21.8648 51.9315V57.3982C21.8608 60.4158 19.4158 62.8608 16.3982 62.8648ZM10.9315 51.9315V57.3982H16.3982V51.9315H10.9315Z"
        fill="#D3DCFF"
      />
      <path
        d="M27.3339 101.134H21.8672V95.6677H27.3339C28.8433 95.6664 30.0659 94.4438 30.0672 92.9344C30.0725 88.4073 33.7401 84.7397 38.2672 84.7344H49.2005V90.201H38.2672C36.7577 90.2024 35.5352 91.4249 35.5339 92.9344C35.5285 97.4615 31.8609 101.129 27.3339 101.134Z"
        fill="#97ADFF"
      />
      <path
        d="M5.46771 71.0664H10.9344C12.4438 71.0664 13.6677 72.2903 13.6677 73.7997V79.2664C13.6677 80.7759 12.4438 81.9997 10.9344 81.9997H5.46771C3.95824 81.9997 2.73438 80.7759 2.73438 79.2664V73.7997C2.73438 72.2903 3.95824 71.0664 5.46771 71.0664Z"
        fill="#97ADFF"
      />
      <path
        d="M10.9333 84.732H5.46667C2.44906 84.728 0.00400392 82.283 0 79.2654V73.7987C0.00400392 70.7811 2.44906 68.336 5.46667 68.332H10.9333C13.9509 68.336 16.396 70.7811 16.4 73.7987V79.2654C16.396 82.283 13.9509 84.728 10.9333 84.732ZM5.46667 73.7987V79.2654H10.9333V73.7987H5.46667Z"
        fill="#D3DCFF"
      />
      <path
        d="M13.6669 92.9336H19.1336C20.6431 92.9336 21.8669 94.1575 21.8669 95.6669V101.134C21.8669 102.643 20.6431 103.867 19.1336 103.867H13.6669C12.1575 103.867 10.9336 102.643 10.9336 101.134V95.6669C10.9336 94.1575 12.1575 92.9336 13.6669 92.9336Z"
        fill="#97ADFF"
      />
      <path
        d="M19.1326 106.599H13.6659C10.6483 106.595 8.20322 104.15 8.19922 101.133V95.6659C8.20322 92.6483 10.6483 90.2032 13.6659 90.1992H19.1326C22.1502 90.2032 24.5952 92.6483 24.5992 95.6659V101.133C24.5952 104.15 22.1502 106.595 19.1326 106.599ZM13.6659 95.6659V101.133H19.1379L19.1326 95.6659H13.6659Z"
        fill="#D3DCFF"
      />
      <path
        d="M123 68.3336H112.066V62.8669H123C124.509 62.8656 125.732 61.6431 125.733 60.1336C125.738 55.6065 129.406 51.9389 133.933 51.9336H144.866V57.4003H133.933C132.424 57.4016 131.201 58.6241 131.2 60.1336C131.194 64.6607 127.527 68.3282 123 68.3336Z"
        fill="#97ADFF"
      />
      <path
        d="M114.801 73.8008H150.334V79.2674H114.801V73.8008Z"
        fill="#97ADFF"
      />
      <path
        d="M153.067 60.1326H147.601C146.091 60.1326 144.867 58.9087 144.867 57.3992V51.9326C144.867 50.4231 146.091 49.1992 147.601 49.1992H153.067C154.577 49.1992 155.801 50.4231 155.801 51.9326V57.3992C155.801 58.9087 154.577 60.1326 153.067 60.1326Z"
        fill="#97ADFF"
      />
      <path
        d="M153.066 62.8648H147.599C144.582 62.8608 142.137 60.4158 142.133 57.3982V51.9315C142.137 48.9139 144.582 46.4688 147.599 46.4648H153.066C156.084 46.4688 158.529 48.9139 158.533 51.9315V57.3982C158.529 60.4158 156.084 62.8608 153.066 62.8648ZM147.599 51.9315H147.594L147.599 57.3982H153.066V51.9315H147.599Z"
        fill="#D3DCFF"
      />
      <path
        d="M142.133 101.134H136.666C132.139 101.129 128.472 97.4615 128.466 92.9344C128.465 91.4249 127.243 90.2024 125.733 90.201H112.066V84.7344H125.733C130.26 84.7397 133.928 88.4073 133.933 92.9344C133.934 94.4438 135.157 95.6664 136.666 95.6677H142.133V101.134Z"
        fill="#97ADFF"
      />
      <path
        d="M158.532 81.9997H153.065C151.556 81.9997 150.332 80.7759 150.332 79.2664V73.7997C150.332 72.2903 151.556 71.0664 153.065 71.0664H158.532C160.042 71.0664 161.265 72.2903 161.265 73.7997V79.2664C161.265 80.7759 160.042 81.9997 158.532 81.9997Z"
        fill="#97ADFF"
      />
      <path
        d="M158.535 84.732H153.068C150.051 84.728 147.606 82.283 147.602 79.2654V73.7987C147.606 70.7811 150.051 68.336 153.068 68.332H158.535C161.553 68.336 163.998 70.7811 164.002 73.7987V79.2654C163.998 82.283 161.553 84.728 158.535 84.732ZM153.068 73.7987H153.063L153.068 79.2654H158.535V73.7987H153.068Z"
        fill="#D3DCFF"
      />
      <path
        d="M150.333 103.867H144.866C143.357 103.867 142.133 102.643 142.133 101.134V95.6669C142.133 94.1575 143.357 92.9336 144.866 92.9336H150.333C151.842 92.9336 153.066 94.1575 153.066 95.6669V101.134C153.066 102.643 151.842 103.867 150.333 103.867Z"
        fill="#97ADFF"
      />
      <path
        d="M150.332 106.599H144.865C141.847 106.595 139.402 104.15 139.398 101.133V95.6659C139.402 92.6483 141.847 90.2032 144.865 90.1992H150.332C153.349 90.2032 155.794 92.6483 155.798 95.6659V101.133C155.794 104.15 153.349 106.595 150.332 106.599ZM144.865 95.6659V101.133H150.332V95.6659H144.865Z"
        fill="#D3DCFF"
      />
      <path
        d="M75.5422 86.0895H68.2831L60.7998 71.6555H60.6737C60.8512 73.9257 60.9399 75.6587 60.9399 76.8545V86.0895H56.0352V65.6016H63.2662L70.7215 79.8394H70.8056C70.6748 77.7748 70.6094 76.1165 70.6094 74.8646V65.6016H75.5422V86.0895Z"
        fill="white"
      />
      <path
        d="M85.4218 86.0895H79.9705V65.6016H92.0783V70.0439H85.4218V73.9537H91.5598V78.396H85.4218V86.0895Z"
        fill="white"
      />
      <path
        d="M104.48 86.0895H98.945V70.128H93.9421V65.6016H109.469V70.128H104.48V86.0895Z"
        fill="white"
      />
    </svg>
  );
};

export default NFTMarketplacesIcon;
