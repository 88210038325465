import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Container = styled.div`
  text-align: center;

  p:not(:first-of-type) {
    max-width: 920px;
    margin: 0 auto;
  }
`;

export const StackList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  margin-bottom: 60px;
`;

export const Title = styled(Text)`
  line-height: 1.21;
  font-size: ${themeGet('fontSizes.mdUp')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.lg')}px;
  }
`;
