import { useTranslation } from 'react-i18next';

import { Container, Title, TechnologiesList } from './technologies.styles';
import { technologiesList } from './technologies-list';
import TechnologyItem from './technology-item/technology-item';

const Technologies = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <p>{t('promo.description')}</p>

      <Title weight="semi">{t('promo.distributedTechnologies.title')}</Title>

      <TechnologiesList>
        {technologiesList.map((technologiesItem) => (
          <TechnologyItem key={technologiesItem.title} {...technologiesItem} />
        ))}
      </TechnologiesList>
    </Container>
  );
};

export default Technologies;
