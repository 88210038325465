import { useTranslation } from 'react-i18next';

import StackItem from './stack-item/stack-item';
import { Container, StackList, Title } from './stack.styles';
import { stackData } from './stack-data';

const Stack = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title weight="semi" mb={5}>
        {t('promo.stack.title')}
      </Title>

      <StackList>
        {stackData.map((stackItem) => (
          <StackItem key={stackItem.title} {...stackItem} />
        ))}
      </StackList>

      <p>{t('promo.stack.ourBenefit')}</p>
    </Container>
  );
};

export default Stack;
