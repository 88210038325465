import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 20px;
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.section')};
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  button {
    margin: 40px auto 0;
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    padding: 50px 50px 60px;
  }
`;

export const Title = styled(Text)`
  font-size: ${themeGet('fontSizes.mdUp')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.xl')}px;
  }
`;

export const Content = styled(Text)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  font-size: ${themeGet('fontSizes.base')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.md')}px;
  }
`;

export const Anchor = styled.a`
  color: ${themeGet('colors.text.light')};
`;

export const ArrowContainer = styled.picture`
  padding: 0 27px;
`;
