import {
  DAppsIcon,
  DeFiIcon,
  NFTMarketplacesIcon,
  MiningSoftwareIcon,
  NearWalletIcon,
  CustomSolutionsIcon
} from 'components/icons/promo';

export const technologiesList = [
  {
    icon: <DAppsIcon />,
    title: 'promo.distributedTechnologies.dApps'
  },
  {
    icon: <DeFiIcon />,
    title: 'promo.distributedTechnologies.DeFi'
  },
  {
    icon: <NFTMarketplacesIcon />,
    title: 'promo.distributedTechnologies.nftMarketplaces'
  },
  {
    icon: <MiningSoftwareIcon />,
    title: 'promo.distributedTechnologies.miningSoftware'
  },
  {
    icon: <NearWalletIcon />,
    title: 'promo.distributedTechnologies.nearIntegratedWallets'
  },
  {
    icon: <CustomSolutionsIcon />,
    title: 'promo.distributedTechnologies.customSolutions'
  }
];
